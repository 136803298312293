<template>
<div>
  <h1>Bälten {{ currentPeriod }}</h1>

  <b-form-select v-if="periods.lengt > 1" v-model="currentPeriod" :options="periods" />

  <b-form-checkbox v-model="showNames">
    Visa namn
  </b-form-checkbox>

  <div>
    <div v-for="rank in ranks" :key="rank">
    <h3><RankImage :rank="rank"/> {{ beltsByRank.get(rank).length }} st</h3>

      <ul v-if="showNames">
        <li v-for="(entry, index) in beltsByRank.get(rank)" :key="index">
          {{ entry.size }} {{ entry.name }}
        </li>
      </ul>

      <ul v-else>
        <li v-for="entry of numbersBySize(beltsByRank.get(rank))" :key="entry.key">{{ entry[0] }}: {{ entry[1] }} st</li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>
import RankImage from '@/components/RankImage.vue'
import { db } from '@/firebaseConfig.js'
import { collection, getDocs, query, where } from 'firebase/firestore'


export default {
  mounted() {
    this.fetchBeltOrders()
  },
  components: { RankImage },
  data() {
    return {
      currentPeriod: 'ht-2024',
      periods: ['vt-2023', 'ht-2023', 'vt-2024', 'ht-2024'],
      promotions: [],
      showNames: false
    }
  },
  computed: {
    belts() {
      return this.promotions
        ? this.promotions.map(p => p.beltSizes).flat().filter(b => b.size != 'inget')
        : []
    },
    ranks() {
      return [ ...new Set(this.belts.map(b => b.rank))].sort()
    },
    beltsByRank() {
      const byRank = new Map()
      this.ranks.forEach(rank => {
        byRank.set(rank, this.belts.filter(b => b.rank === rank).sort((a, b) => a.size - b.size))
      })
      return byRank
    }
  },
  methods: {
    async fetchBeltOrders() {
      const promotions = []
      const q = query(collection(db, 'promotions'), where('period', '==', this.currentPeriod));
      let promotionsSnapshot = await getDocs(q)
      promotionsSnapshot.forEach(doc => {
        promotions.push({ id: doc.id, ...doc.data() })
      })
      this.promotions = promotions
    },
    numbersBySize(list) {
      const bySize = new Map()
      const sizes = new Set(list.map(b => b.size))
      sizes.forEach(size => {
        bySize.set(size, list.filter(b => b.size === size).length)
      })
      return bySize
    }
  }
}
</script>
